'use client';

import { useEffect } from 'react';

import { useSession } from '@/contexts/session-context';
import { usePushToRN } from '../hooks/use-push-to-rn';

export const RnBridge = () => {
   const { data: session } = useSession();
   const { send: sendWebLoaded } = usePushToRN('web-loaded');
   const { send: sendPushUser } = usePushToRN('push-user');

   const sessionEmail = session?.email;

   useEffect(() => {
      sendWebLoaded();
   }, [sendWebLoaded]);

   useEffect(() => {
      if (!sessionEmail) {
         return;
      }

      sendPushUser({
         patientId: sessionEmail,
      });
   }, [sendPushUser, sessionEmail]);

   return null;
};
