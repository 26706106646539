'use client';

import { Alert, AlertIcon, AlertTitle, Button } from '@chakra-ui/react';

import { getClientPublicRuntimeConfig } from '@/services/env/utils/client-public-runtime-config';
import { Icon } from '../Icon';
import { useAppVersionQuery } from '@/services/env/hooks/use-app-version-query';
import { motion, AnimatePresence } from 'framer-motion';

export const UpdateAvailableBanner = () => {
   const { data: appVersion } = useAppVersionQuery();
   const newAppVersionAvailable =
      getClientPublicRuntimeConfig().appVersion !== appVersion &&
      appVersion !== undefined;

   if (!newAppVersionAvailable) {
      return null;
   }

   return (
      <AnimatePresence>
         {newAppVersionAvailable ? (
            <motion.div
               initial={{ opacity: 0, height: 0 }}
               animate={{ opacity: 1, height: 'auto' }}
               exit={{ opacity: 0, height: 0 }}
               transition={{ duration: 0.5 }}
            >
               <Alert p="2" status="warning">
                  <AlertIcon boxSize="4">
                     <Icon name="info" boxSize="full" />
                  </AlertIcon>
                  <AlertTitle
                     flexGrow={1}
                     fontWeight={500}
                     fontSize="xs"
                     lineHeight={1.3}
                  >
                     A new version of the app is available! To enjoy the latest
                     experience, please click Refresh.
                  </AlertTitle>
                  <Button
                     flexShrink={0}
                     flexGrow={0}
                     onClick={() => {
                        window.location.reload();
                     }}
                     size="xs"
                     fontWeight={500}
                  >
                     Refresh
                  </Button>
               </Alert>
            </motion.div>
         ) : null}
      </AnimatePresence>
   );
};
